@import '../../styles/variables.scss';

@media (max-width: 767px) {
  div.mobile.page-template-container {
    display: block;
    position: relative;
    width: 100vw;
    height: 100vh;
    padding: 0;

    div.page-template-cover-container {
      position: fixed;
      width: 100vw;
      height: 65vh;
      padding: 0;
      border-radius: 0;

      div.component.cover-container {
        position: sticky;
        height: 60vh;
        overflow: hidden;
        border-radius: 0;
      }
    }

    div.page-template-content-container {
      position: absolute;
      top: 60vh;
      flex: 1;
      overflow: auto;
      margin: -$radius 0 0 0;
      background-color: $color-neutral;
      box-shadow: $body-shadow-outset;
      border-top-right-radius: $radius;
      border-top-left-radius: $radius;
      color: #000;
      z-index: 3;
      width: calc(100vw - (2 * $padding));
      min-height: calc(100vh - 60vh - $radius);
      padding-bottom: 3rem;

      div.mobile.actions {
        display: block;
      }
      
      div.page-template-cover-container {
        border-radius: $radius;
        
      }
    }
  }

}
