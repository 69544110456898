@import '../../styles/variables.scss';

@media (max-width: 767px) {
    section {
        padding: 0;
        width: 100%;
        height: 100%;
    
        div.restaurant-page.cover-container {
            border-radius: 0;
        }
    }
}