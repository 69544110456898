@import '../../styles/variables.scss';

div.page-template-container{
    display: flex;
    overflow-y: auto;
    height: 100vh;

    div.page-template-cover-container{
        height: calc(100vh - (2 * $padding));
        padding: $padding 0 $padding $padding;
        flex: 1;
        .component.cover-container {
            border-radius: $radius;
            overflow: hidden;
        }
    }

    div.page-template-content-container{
        flex: 1;
        overflow: auto;
        color: #000;
        background-color: $color-neutral;
        padding: 1rem $padding;

        div.back-actions{
            width: 100%;
            display: flex;
            align-items: center;
            margin-left: 0.5rem;
            margin-bottom: 1rem;

            div.main-actions {
                flex: 1;
                margin-top: 0.5rem;
                padding-right: 2rem;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                color: $color-primary;
                cursor: pointer;
                gap: 0.5rem;
                p{
                    color: #000;
                    font-weight: bold;
                }
            }

            button.btn-back{
                border: none;
                background-color: transparent;
                font-size: 1.1rem;
                color: rgba($color: #4b4848, $alpha: 0.8);
                margin-right: 0rem;
                margin-top: 0.5rem;
                cursor: pointer;

                &:hover{
                    background-color: #f4f4f4;
                }
            }
        }

        div.mobile.actions {
            display: none;
        }
    }
}
