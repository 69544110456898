@import '../../styles/variables.scss';

@media (max-width: 767px) {
    div.component.modal {
        div.modal-container {
            position: absolute;
            padding: 5rem 3rem;
            
            width: calc(100vw - 6rem);
            height: 50vh;
            
            border-top-left-radius: $radius;
            border-top-right-radius: $radius;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            bottom: 0;
        }
    }
}