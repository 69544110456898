div.component.cover-container {
    position: relative;

    background-image: url('../../assets/images/bg.webp');
    background-size: cover;
    background-position: center;

    width: 100%;
    height: 100%;

    div.cover {
        scrollbar-width: thin;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 100%;


        z-index: 2;


        h1,
        p {
            color: #fff;
            margin: 0;
        }

        h1 {
            font-size: 2.375rem;
            font-family: 'helvetica Neue',  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
            font-weight: 700;
        }

        p {
            padding: 0;
            font-size: 1.125rem;
        }

        .cover-btn{
            margin: 1rem;
            .btn-content {
                margin-left: auto;
                margin-right: auto;                
            }
            .btn-contact-text {
                margin-left: 10px;
            }
        }

        div.logo {
            img {
                width: 3rem;
                height: 3.1875rem;
                margin: 1rem;
            }
        }
        p.title-home {
            margin-bottom: 0.5rem;
        }
    }

    div.darker {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1;
    }
}
