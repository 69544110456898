@import '../../styles/variables.scss';

button.scroll-up-button {
    padding: $button-padding;
    width: 100%;
    color: #7A7A7A;
    background-color: $color-neutral;
    border: none;
    margin-top: 2rem;

    div.scroll-up-button-content {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        font-size: $button-font-size;

    }
}
