@import '../../styles/variables.scss';

div.booking-details-container {
    width: 90%;
    height: 100%;
    background-color: #fff;
    border-radius: 0.4rem;
    padding-bottom: 2%;
    margin: 0.8rem;
    overflow: hidden;

    div.first-div {
        display: flex;
        margin-bottom: 0.4rem;

        div.booking-details-name {
            margin-top: 1.5rem;
            display: flex;
            flex-direction: column;
            margin-left: 8%;
            width: 40%;

            .booking-details-name-1 {
                text-align: left;
                font-size: 1.1rem;
                
                color: #776e6e;
                margin-bottom: 0.3rem;
            }

            .booking-details-name-2 {
                text-align: left;
                font-size: 1.1rem;
                
            }
        }

        div.booking-details-time {
            margin-top: 1.5rem;
            display: flex;
            flex-direction: column;
            margin-left: 15%;

            .booking-details-time-1 {
                text-align: left;
                font-size: 1.1rem;
                
                color: #776e6e;
                margin-bottom: 0.3rem;
            }
            
            .booking-details-time-2 {
                text-align: left;
                font-size: 1.1rem;
                
            }
        }
    }

    div.second-div {
        display: flex;
        margin-bottom: 1.2rem;
        div.booking-details-date {
            margin-top: 1.5rem;
            display: flex;
            flex-direction: column;
            margin-left: 8%;
            width: 40%;

            .booking-details-date-1 {
                text-align: left;
                font-size: 1.1rem;
                
                color: #776e6e;
                margin-bottom: 0.3rem;
            }

            .booking-details-date-2 {
                text-align: left;
                font-size: 1.1rem;
                
            }
        }

        div.booking-details-guests {
            margin-top: 1.5rem;
            display: flex;
            flex-direction: column;
            margin-left: 15%;

            .booking-details-guests-1 {
                text-align: left;
                font-size: 1.1rem;
                
                color: #776e6e;
                margin-bottom: 0.3rem;
            }
            
            .booking-details-guests-2 {
                text-align: left;
                font-size: 1.1rem;                
            }
        }
    }
    div.third-div {
        display: flex;
        margin-bottom: 0.4rem;
        margin-left: 8%;

        div.booking-details-confirmation-container {
            display: flex;
            margin-top: 0.8rem;
            width: 59%;
            button.booking-details-status {
                height: 2rem;
                border: none;
                border-radius: 0.4rem;
                width: auto;
                padding-left: 1rem;
                padding-right: 1rem;
                font-size: 1.1rem;
                
                &.confirmed {
                    color: #45a464;
                    background-color: #eaf2e0;

                    .font {
                        padding-right: 0.5rem;
                    }
                }

                &.refused {
                    color: #de3030;
                    background-color: #f6d9d8;
                    .font {
                        display: none;
                    }
                }

                &.pending{
                    color: #fc9e12;
                    background-color: #fbecd5;
                    .font {
                        display: none;
                    }
                }
            }
        }

        div.booking-details-subsidiary {
            display: flex;
            flex-direction: column;
            align-items: start; 
            width: 11%;
            .booking-details-subsidiary-1 {
                text-align: left;
                font-size: 1.1rem;
                margin-left: 9%;
                                
                color: #776e6e;
                margin-bottom: 0.3rem;
            }
            
            .booking-details-subsidiary-2 {
                width: 300%;
                text-align: left;
                padding-left: 10%;
                font-size: 1.1rem;                
            }
        }
    }

    .booking-confirmation {
        display: flex;
        margin-top: 0.8rem;
        margin-left: 8%;

        button.booking-validated {
            height: 2rem;
            border: none;
            border-radius: 0.4rem;
            width: auto;
            font-size: 1.1px;
            background-color: $color-primary;
            color: #fff;
            &:hover {
                background-color: $color-ineteractive-hover;
            }

            &.disabled {
                pointer-events: none;
                opacity: 0.5;
                cursor: not-allowed;
            }
        }

        .booking-refuse {
            height: 2rem;
            border: none;
            border-radius: 0.4rem;
            width: auto;
            margin-left: 0.5rem;
            font-size: 1.1rem;
            background-color: $color-red;
            color: #fff;
            &:hover {
                background-color: $color-red-light;
            }

            &.disabled {
                pointer-events: none;
                opacity: 0.5;
                cursor: not-allowed;
            }
        }

        .button.booking-update {
            height: 2rem;
            border: none;
            border-radius: 0.4rem;
            width: auto;
            margin-left: 0.5rem;
            font-size: 1.1rem;
            background-color: #eff3f1;
            color: #fff;
        
            &:hover {
                background-color: #d3d3d3;
            }
        }

        .refuse-popup {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #ffffff;
            border-radius: 10px;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
            padding: 20px;
            max-width: 400px;
            width: 100%;
            text-align: center;
            z-index: 1000;
          }
          
          .refuse-popup p {
            font-family: $font-family;
            font-size: 1.1rem;
            margin-bottom: 20px;
          }
          
          .refuse-popup textarea {
            font-family: $font-family;
            width: 100%;
            padding: 15px;
            margin-bottom: 20px;
            border: 1px solid #ddd;
            border-radius: 5px;
            box-sizing: border-box;
          }
          
          .refuse-popup-confirmation {
            background-color: $color-primary;
            color: #fff;
            border: none;
            padding: 10px 30px;
            font-size: 1.1rem;
            border-radius: 5px;
            transition: background-color 0.3s ease;
            &:hover {
                background-color: $color-ineteractive-hover;
              }  
          }
          
          .refuse-popup-annulation {
            background-color: $color-red;
            color: #fff;
            border: none;
            padding: 10px 30px;
            font-size: 1.1rem;
            margin-left: 10px;
            border-radius: 5px;
            &:hover {
                background-color: $color-red-light;
            }
          }
    }

    .booking-confirmation {
        display: flex;
        margin-top: 0.8rem;
        margin-left: 8%;

        button.booking-validated {
            height: 2rem;
            border: none;
            border-radius: 0.4rem;
            width: auto;
            font-size: 1.1rem;
            color: #ececec;
            background-color: #28A745;
            &:hover {
                background-color: #218838;
            }
        }

        .booking-refuse {
            height: 2rem;
            border: none;
            border-radius: 0.4rem;
            width: auto;
            margin-left: 0.5rem;
            font-size: 1.1rem;
            color: #ececec;
            background-color: #DC3545;
            &:hover {
                background-color: #c82333;
            }
        }
    }
}

@media screen and (max-width: 1600px) {
    div.booking-details-container {
        width: 90%;
        height: 100%;
        background-color: #fff;
        border-radius: 0.4rem;
        padding-bottom: 2%;
        margin: 0.8rem;
        overflow: hidden;

        div.first-div {
            div.booking-details-name {
                .booking-details-name-1 {
                    text-align: left;
                    font-size: 0.9rem;
                    
                    color: #776e6e;
                    margin-bottom: 0.3rem;
                }
    
                .booking-details-name-2 {
                    text-align: left;
                    font-size: 0.87rem;   
                }
            }
    
            div.booking-details-time {
    
                .booking-details-time-1 {
                    text-align: left;
                    font-size: 0.9rem;
                    
                    color: #776e6e;
                    margin-bottom: 0.3rem;
                }
                
                .booking-details-time-2 {
                    text-align: left;
                    font-size: 0.87rem;
                    
                }
            }
        }

        div.second-div {
            div.booking-details-date {    
                .booking-details-date-1 {
                    text-align: left;
                    font-size: 0.9rem;
                    color: #776e6e;
                    margin-bottom: 0.3rem;
                }
    
                .booking-details-date-2 {
                    text-align: left;
                    font-size: 0.87rem;
                    
                }
            }
    
            div.booking-details-guests {
    
                .booking-details-guests-1 {
                    text-align: left;
                    font-size: 0.9rem;                    
                    color: #776e6e;
                    margin-bottom: 0.3rem;
                }
                
                .booking-details-guests-2 {
                    text-align: left;
                    font-size: 0.87rem;                
                }
            }
        }

        div.third-div {
            display: flex;
            margin-bottom: 0.4rem;
            margin-left: 8%;

            div.booking-details-confirmation-container {
                display: flex;
                margin-top: 0.8rem;
                width: 18%;
                button.booking-details-status {
                    height: 2rem;
                    border: none;
                    border-radius: 0.4rem;
                    width: auto;
                    padding-left: 1rem;
                    padding-right: 1rem;
                    font-size: 0.9rem;
                    
                    &.confirmed {
                        display: flex;
                        color: #45a464;
                        padding-top: 0.5rem;
                        background-color: #eaf2e0;
                    }

                    &.refused {
                        color: #de3030;
                        background-color: #f6d9d8;
                        .font {
                            display: none;
                        }
                    }

                    &.pending{
                        color: #fc9e12;
                        background-color: #fbecd5;
                        .font {
                            display: none;
                        }
                    }
                }
            }

            div.booking-details-subsidiary {
                display: flex;
                flex-direction: column;
                align-items: start; 
                width: 100%;
                .booking-details-subsidiary-1 {
                    font-size: 0.9rem;                    
                    color: #776e6e;
                    margin-bottom: 0.3rem;
                    padding-left: 42%;
                }
                
                .booking-details-subsidiary-2 {
                    text-align: left;
                    font-size: 0.87rem;
                    word-break: break-all;
                    padding-left: 51%;
                }
            }
        }

    }
}