@import '../../styles/variables.scss';

.header-container {
  display: flex;
  margin-bottom: 1.5rem;
  width: 90%;
  height: 100%;
  border-radius: 0.4rem;
  padding-left: 1rem;

  .header-container-first {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    .header-name {
      text-align: left;
      font-size: 1.5rem;
      font-weight: bolder;
      color: $color-primary;
      margin-bottom: 0.4rem;
    }
    .header-name-2 {
      text-align: left;
      font-size: 1rem;
      color: #212121;
      font-weight: 500;
    }
  }
  .header-booking-number {
    background: #fff;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 0.5rem;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 2%;
    .booking-number {
      font-size: 30px;
      font-weight: bolder;
    }

    .booking-text {
      color: #212121;
      font-size: 14px;
    }
  }
}

.btns-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  width: 90%;
  border-radius: 0.4rem;
  padding-left: 1rem;
  .btn-recent {
    border: 1px solid #E3E3E3;
    background-color: white;
    border-radius: 0.3rem;
    padding: 0.7rem 1.5rem;
    padding-left: 0.8rem;
    display: flex;
    font-family: $font-family;
    .btn-recent-text {
      font-size: 0.8rem;
      color: $color-contrast-higher;
    }
    .btn-recent-icon {
      margin-left: 0.4rem;
      margin-right: 0.4rem;
    }

    &:hover {
      background-color: #f8f6f6;
      color: #333;
    }
  }
  .btns-filter {
    border: 1px solid #FFFFFF;
    background: #FFFFFF;
    border-radius: 0.4rem;
    padding: 0.7rem 1.5rem;
    margin-left: 23rem;
    display: flex;
    font-family: $font-family;
    align-items: center;
    .btn-filter-text {
      font-size: 0.7rem;
      color: #212121;
    }
    .btn-filter-icon {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
    &:hover {
      background: #d2d3d2;
    }
    .btn-filter-number-selected {
      margin-left: 0.5rem;
      width: 20px;
      height: 20px;
      background-color: $color-green;
      font-family: $font-family;
      font-size: 0.7rem;
      border-radius: 50%;
      color: white;
      text-align: center;
      line-height: 20px;
    }
  }

  .btns-export {
    width: 50px;
    margin-left: 1.8rem;
    background-color: white;
    border: 1px solid #EDEDED;
    border-radius: 0.4rem;

    &:hover {
      background-color: #f8f6f6;
      color: #333;
    }
  }
}
hr.bar-decor {
  border: 0.5px solid #E3E3E3;
  margin-top: 1.5rem;
  width: 103%;
  margin-left: -2%;
  margin-bottom: 1.5rem;
  display: flex;
}

@media screen and (max-width: 1450px) {
  hr.bar-decor {
    border: 0.5px solid #E3E3E3;
    width: 104%;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    display: flex;
  }
  .btns-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    width: 90%;
    border-radius: 0.4rem;
    padding-left: 1rem;
    .btns-filter {
      border: 1px solid #FFFFFF;
      background: #FFFFFF;
      border-radius: 0.4rem;
      padding: 0.7rem 1.5rem;
      margin-left: 3.2rem;
      display: flex;
      font-family: $font-family;
      align-items: center;
      .btn-filter-text {
        font-size: 0.7rem;
        color: #212121;
      }
      .btn-filter-icon {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
      &:hover {
        background: #d2d3d2;
      }
    }
  }
}

