div.booking-detail {
    width: 50%;
    margin-left: 20%;
}

@media screen and (max-width: 768px) {
    div.booking-detail {
        margin-left: 8%;
        width: 90%;
    }
}