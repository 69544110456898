@import '../../styles/variables.scss';

button.standard-btn {
    border: none;
    border-radius: 0.5rem;

    width: 20.875rem;
    height: 3.75rem;
    font-family:$font-family;
    background-color: #fff;

    font-weight: 700;
    font-size: 1rem;
    color: $color-ineteractive-active;

    margin: 0.3rem;

    cursor: pointer;

    &.green {
        background-color: $color-ineteractive-active;
        color: #ffffff;
      }

    &:hover {
        background-color: $color-ineteractive-hover;
        color: #fff;
    }

    &:disabled {
        color: #add7c6;
    }

    &.cancel {
        color: #bfbfbf;
        background-color: transparent;
        font-size: 1rem;

        &:hover {
            background-color: #f4f4f4;
        }
    }
    &.book-time {
        width: 10rem;
    }

    &.number {
        width: 6.5rem;
    }

    &.request-button {
        margin: 0;
        width: 100%;
        background-color: $color-ineteractive-active;
        color: #fff;
        justify-content: space-between;
        font-family: $font-family; 
        font-weight: 400;
        height: 2.8rem;
        font-size: 0.8rem;
    }

    &.active{
        color: $color-ineteractive-active;
        background-color: $color-ineteractive-active-transparent;
    }

    &.done{
        color: #fff;
        background-color: $color-primary;
    }

    &.request-button.cancel {
        margin-top: 0.5rem;
        width: 100%;
        height: 2.8rem;
        font-size: 0.8rem;
        color: #000;
        font-weight: 500;
        background-color: #E7E7E7;
        font-family: $font-family;
    }
}

@media screen and (max-width: 768px) {
    button.standard-btn {
        width: 13.875rem;
    }
}
