@import '../../styles/variables.scss';

div.form-item-details {
    display: flex;
    flex-direction: column;
    border-radius: 0.3rem;
    width: 40vh;
    padding: 0.5rem;

    label {
        display: flex;
        font-size: 0.7rem;
        color: $color-label-card;
    }

    input.form-item-content {
        background-color: transparent;
        border: none;
        color: #000;
        font-size: 1.1rem;
        font-weight: bold;
    }
    &.only-date{
        margin-left: 0;
        width: 100%;
        box-sizing: border-box;
        flex: 1;
    }

    &.only-hour{
        width: 17vh;
        overflow: visible;      
    }
}