@import '../../styles/variables.scss';

button {

  transition: all 200ms ease-in-out;
}

div.btn-content{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;

  gap: 1rem;

}

/*success button*/
.success {
  appearance: none;
  background-color: $color-ineteractive-active;
  border: 1px solid rgba(27, 31, 35, .15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  box-sizing: border-box;
  color: $color-neutral-light;
  cursor: pointer;
  display: inline-block;
  font-family: $font-family;
  font-size: 14px;
  font-weight: 400;
  line-height: px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  margin-bottom: 1rem;
}

.success:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.success:hover {
  transform: scale(1.1);
  background-color: $color-ineteractive-hover;
}

.success:focus {
  box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
  outline: none;
}

.success:disabled {
  background-color: #94d3a2;
  border-color: rgba(27, 31, 35, .1);
  color: rgba(255, 255, 255, .8);
  cursor: default;
}

.success:active {
  background-color: $color-ineteractive-active;
  box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}

/*Failure button*/
.failure {
  background-color: $color-red;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: $color-neutral-light;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 20px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.failure:hover,
.failure:focus {
  background-color: $color-red-light;
}

/*Gray button*/
.info {
  appearance: none;
  background-color: $color-contrast-high;
  border: 1px solid rgba(27, 31, 35, .15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  box-sizing: border-box;
  color: $color-neutral-light;
  cursor: pointer;
  display: inline-block;
  font-family: "helvetica Neue", -apple-system, BlinkMacSystemFont,
  "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  margin: 5px;
}

.info:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.info:focus {
  box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
  outline: none;
}

.info:disabled {
  background-color: #94d3a2;
  border-color: rgba(27, 31, 35, .1);
  color: rgba(255, 255, 255, .8);
  cursor: default;
}

.info:active {
  background-color: #298e46;
  box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}

.btn-booking {
  appearance: none;
  background-color: $color-ineteractive-active;
  border: 1px solid rgba(27, 31, 35, .15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  box-sizing: border-box;
  color: $color-neutral-light;
  cursor: pointer;
  display: inline-block;
  font-family: $font-family;
  font-size: 14px;
  font-weight: 400;
  padding: 6px 16px;
  position: relative;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  margin-bottom: 1rem;
  width: 100%;
}
.btn-booking:hover {
  transform: scale(1.1);
  background-color: $color-ineteractive-hover;
}

.btn-contact {
  appearance: none;
  background-color: $color-contrast-high;
  border: 1px solid rgba(27, 31, 35, .15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  box-sizing: border-box;
  color: $color-neutral-light;
  cursor: pointer;
  display: inline-block;
  font-family: $font-family;
  font-size: 14px;
  font-weight: 400;
  padding: 6px 16px;
  position: relative;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  margin-bottom: 1rem;
  width: 100%;
}
