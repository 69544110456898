@import '../../styles/variables.scss';

section {
    padding: $padding;
    margin: 0;

    width: calc(100vw - (2 * $padding));
    height: calc(100vh - (2* $padding));
    overflow-x: hidden;
    overflow-y: hidden;

    div.restaurant-page.cover-container {
        border-radius: 20px;
        overflow: hidden;
        height: 100%;
    }
}