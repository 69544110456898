$padding: 1rem;
$radius: 20px;

/* Color */
$color-primary: #006039;
$color-secondary: #A37E2C;

$color-ineteractive-hover: #00A05F;
$color-ineteractive-active: #008951;
$color-ineteractive-active-transparent: #00895122;
$color-interactive-pressed: #006F42;

$color-red: #dd1d1a;
$color-red-light: #f6d9d8;

$color-green: #44A763;
$color-green-light: #eaf2e0;

$color-orange: #ff9800;
$color-orange-light: #fbecd5;

$color-neutral: #f9f9f9;
$color-neutral-light: #ffffff;
$color-neutral-gray: #dcdcdc;
$color-neutral-gray-light: #e7e7e7;

$color-contrast-low: #d6d6d6;
$color-contrast-lower: #a6a6a6;
$color-contrast-medium: #7a7a7a;
$color-contrast-high: #404040;
$color-contrast-higher: #212121;
$color-label-card: #7A7A7A;


/* Button */
$button-padding: 1rem 2rem;
$button-font-size: 1.1rem;

/* Input */
$input-border: 1px solid #F0F0F0;
$input-padding: calc($padding / 2) $padding;

/* Shadow */
$body-shadow-outset: 0 0 30px #38383829;

$font-family: 'helvetica-now-text',  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;

/* Font */
@font-face {
  font-family: "RolexFont-Bold";
  src: local("RolexFont-Bold"),
    url("./font/ROLEXFONT-BOLD.OTF") format("truetype");
}

@font-face {
  font-family: "RolexFont-Light";
  src: local("RolexFont-Light"),
    url("./font/ROLEXFONT-LIGHT.OTF") format("truetype");
}

@font-face {
  font-family: "RolexFont-Regular";
  src: local("RolexFont-Regular"),
    url("./font/ROLEXFONT-REGULAR.OTF") format("truetype");
}
