@import '../../../styles/variables.scss';

div.submit-page-content {
    position: relative;
    background-color: white;
    margin-top: 3rem;
    margin-bottom: 1rem;
    padding: 2rem;
    border-radius: 1rem;
    box-sizing: border-box;

    &::before {
        content: "";
        position: absolute;
        top: 0rem;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 4rem;
        height: 4rem;
        background-color: #F7F7F7;
        border-radius: 50%;
    }

    &::after {
        content: url('../../../assets/images/logo_couronne.webp');
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.3);
        border-radius: 50%;
    }
}


div.actions {
    display: flex;
    flex-direction: column;
    gap: 1rem;

}

div.event-date-time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
    div.submit-page-content {
        width: 15rem;
        box-sizing: border-box;
    }
}
