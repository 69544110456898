@import '../../styles/variables.scss';



form.reservation-booking-form{
    display: flex;
    justify-content: center;
}

h1,
p {
    color: black;
}

div.page-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

div.page-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 0.5rem;

    margin-top: 1.5rem;
    margin-bottom: 0.5rem;

    h1 {
        letter-spacing: normal;
        margin: 0;
        font-size: 1.4rem;
        margin-top: 0.3rem;
        margin-bottom: 0.5rem;
    }

    p {
        padding: 0;
        font-size: 0.9rem;
        font-weight: 500;
    }

    h1.title-second {
        margin-bottom: 3rem;
    }

    h1.third-title {
        margin-bottom: 3rem;
    }
}


button.btn-booking-icon {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #fff;
    color: grey;

    padding: 0.3rem 0.5rem;
    border-radius: 0.4rem;
    border: none;

    font-size: 1rem;

    // anti aliasing
    -webkit-font-smoothing: antialiased;

    :not(:first-child) {
        margin-left: 0.25rem;
    }


    cursor: pointer;

    &.active{
        color: $color-ineteractive-active;
        background-color: $color-ineteractive-active-transparent;
    }

    &.done{
        color: #fff;
        background-color: $color-ineteractive-active;
        font-family:$font-family;
        font-size: 0.8rem;
        padding: 0.4rem 0.9rem;
        border-radius: 8px;
        gap: 0.2rem;
    }
}

div.numbers-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    div.number-group {
        width: 30%;
        padding: 0 0.25rem;
        button {
            width: 100%;
        }
    }
}
div.confirm-creation {
    .submit-img-logo {
        width: 9rem;
        aspect-ratio: 1/1;
    }
}

p.confirm-message-small {
    font-size: 0.9rem;
    font-weight: 400;
    color: $color-contrast-higher;
    font-family:$font-family;
    margin-bottom: 2rem;
}

p.confirm-message {
    font-size: 1.1rem;
    font-weight: 700;
    font-family:$font-family;
}

.font-icon {
    font-size: 0rem;
}

@media screen and (max-width: 600px) {
    div.confirm-creation {
        padding-bottom: 8rem;
        p.confirm-message-small {
            font-size: 0.9rem;
            font-weight: 400;
            color: $color-contrast-higher;
            font-family:$font-family;
            margin-bottom: 2rem;
        }
        
        p.confirm-message {
            font-size: 1.1rem;
            font-weight: 700;
            font-family:$font-family;
        }
    }
    
    div.page-title h1 {
        font-size: 1.2rem;
        padding: 1rem;
    }
}
