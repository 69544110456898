html, body {
    padding: 0;
    margin: 0;

    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;

    font-family:'helvetica-now-text',  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    
    background-color: #fafafa;
}

::-webkit-scrollbar {
    width: 0rem;
}

main {
    padding: 0;
    margin: 0;

    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
}
