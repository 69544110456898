@import '../../styles/variables.scss';


div.component.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    backdrop-filter: blur(20px);
    
    div.modal-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        
        background-color: $color-neutral-light;
        padding: 5rem 3rem;
        width: 20vw;
        min-width: 15rem;
        border-radius: $radius;
        box-shadow: $body-shadow-outset;
        
        div.close-icon {
            position: absolute;
            top: 1rem;
            right: 1rem;
            margin: 1rem;
            cursor: pointer;
        
        }

        div.modal-content {
            width: 15rem;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
    }
}