@import '../../styles/variables.scss';

select.select-text-field {
    -webkit-appearance: none; /* Style WebKit/Blink (Chrome, Safari, Opera) */
    -moz-appearance: none; /* Style Gecko (Firefox) */
    appearance: none;
    height: 3rem;
    color: #000;
    background-color: #fff;
    border-color: #00000022;
    border-radius: 0.4rem;
    margin: 0.3rem;
    padding: 0.8rem;
    border: $input-border;
    font-size: 0.9rem;
    font-family: $font-family;
    width: calc(110% - (2 * 1rem));
}

input.txt-standard {
    height: 2rem;
    color: #000;
    background-color: #fff;

    border-color: #00000022;
    border-radius: 0.4rem;

    margin: 0.3rem;
    padding: $input-padding;
    border: $input-border;
    font-size: 0.8rem;
    font-family:$font-family;
    width: calc(100% - (2 * $padding));
    &.error {
      border: 1px solid red;
    }

    &:focus{
        border-color: #ff0000aa;
    }

    &:hover{
        border-color: #0000ff55;
    }
}
.text-primary {
  width: 11rem;
  height: 1.5rem;
  color: #0d6efd;
}

.text-primary:hover {
  color: #0a58ca;
}

.text-primary:focus, .text-primary.focus {
  color: #0a58ca;
}

.text-primary-disabled, .text-primary:disabled {
  color: #6c757d;
}

.text-primary:not(:disabled):not(.disabled):active, .text-primary:not(:disabled):not(.disabled).active {
  color: #0a58ca;
}


.error-message {
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  font-size: 0.8rem;
  color: red;
}