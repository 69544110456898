@import '../../styles/variables.scss';

.item-global {
  .item-hr {
    border-top: 1px solid #EFEFEF;
    width: 50%;
    margin-left: 20%;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    align-items: baseline;
  }

  div.item {
    width: 50%;
    height: 2.5rem;
    margin-left: 20%;
    background-color: #fff;
    border-radius: 0.4rem;
    margin-top: 0.8rem;
    display: flex;
    margin-bottom: 0.8rem;

    div.item-discription {
      margin-top: 0.5rem;
      margin-left: 1.5rem;
      font-family: $font-family;
      font-size: 0.8rem;
      text-align: left;
      width: 27%;
    }

    div.item-value {
      margin-top: 0.5rem;
      width: 25%;
      text-align: left;
      position: relative;
      cursor: pointer;
      pointer-events: auto;
      margin-left: 18%;
      input.item-value-input {
        width: 170%;
        align-items: start; 
        border: none;
        background-color: transparent;
        font-family: $font-family;
        background-color: transparent;
        text-align: right;
        font-size: 0.78rem;
        &.error {
          border: 1px solid red;
        }  
      }
      .item-value-select-date {
          align-items: start; 
          font-family: $font-family;
          appearance: none;
          position: absolute;
          -webkit-appearance: none;
          -moz-appearance: none;
          padding: 5px;
          margin-left: 33%;
          border: none;
          background-color: transparent;
          text-align: right;
          font-size: 0.78rem;
          text-align: right;
          pointer-events: auto;        
        }

      .item-value-select {
        align-items: start; 
        font-family: $font-family;
        appearance: none;
        position: absolute;
        -webkit-appearance: none;
        -moz-appearance: none;
        padding: 5px;
        margin-left: 33%;
        border: none;
        background-color: transparent;
        text-align: right;
        font-size: 0.78rem;
      }
      .item-value-select-hour {
        width: 76%;
        margin-left: 100%;
      }
      .item-value-select-subsidiary{
        font-family: $font-family;
        appearance: none;
        position: absolute;
        -webkit-appearance: none;
        -moz-appearance: none;
        padding: 5px;
        text-align: right;
        margin-left: 71%;
        border: none;
        background-color: transparent;
        font-size: 0.78rem;
      }

      .item-value-select::-ms-expand {
        display: none;
      }

      .item-value-select::-webkit-inner-spin-button,
      .item-value-select::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .item-value-select:after {
        content: '\25BC';
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        color: #555;
      }

      .booking-details-status {
        font-family: $font-family;
        height: 1.8rem;
        border: none;
        border-radius: 0.4rem;
        margin-top: -30%;
        width: auto;
        font-size: 0.8rem;

        &.confirmed {
          color: #45a464;
          background-color: #eaf2e0;
        }

        &.refused {
          color: #de3030;
          background-color: #f6d9d8;
        }

        &.pending {
          color: #fc9e12;
          background-color: #fbecd5;
        }
      }
    }

    div.item-icon {
      margin-top: 0.5rem;
      width: 30%;
      text-align: right;

      button.edit-item-btn {
        background-color: transparent;
        border: none;
        color: #c6c6c6;
      }

      button.edit-item-btn.active .font {
        color: $color-ineteractive-active;
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .item-global {
    div.item {
      margin-left: 8%;
      width: 90%;
      height: 2.5rem;
      background-color: #fff;
      border-radius: 0.4rem;
      margin-top: 0.8rem;
      display: flex;
      margin-bottom: 0.8rem;

      div.item-discription {
        margin-left: 0.8rem;
        font-size: 0.7rem;
        text-align: left;
        width: 28%;
      }

      div.item-value {
        margin-top: 0.5rem;
        width: 25%;
        text-align: left;
        position: relative;
        cursor: pointer;
        pointer-events: auto;
        margin-left: 15%;
        input.item-value-input {
          width: 160%;
          align-items: start; 
          border: none;
          font-family: $font-family;
          background-color: transparent;
          text-align: right;
          font-size: 0.7rem;
          
        }
        .item-value-select-date {
          width: 200%;
          margin-left: auto;
          text-align: left;
          pointer-events: auto;        
        }

        .item-value-select-subsidiary{
          width: 130%;
          margin-left: 2.2rem;
          align-items: start; 
          border: none;
          font-family: $font-family;
          background-color: transparent;
          text-align: right;
          font-size: 0.7rem;
        }

        .item-value-select {
          margin-left: -20%;
        }
        .item-value-select-hour {
          margin-left: 91%;
        }
        .booking-details-status {
          margin-left: -30%;
          height: 1.8rem;
          border: none;
          border-radius: 0.4rem;
          margin-top: -30%;
          width: auto;
          font-size: 0.7rem;

          &.confirmed {
            color: #45a464;
            background-color: #eaf2e0;
          }

          &.refused {
            color: #de3030;
            background-color: #f6d9d8;
          }

          &.pending {
            color: #fc9e12;
            background-color: #fbecd5;
          }
        }
      }

      div.item-icon {
        margin-top: 0.5rem;
        width: 28%;
        text-align: right;

        button.edit-item-btn {
          background-color: transparent;
          border: none;
          color: #c6c6c6;
        }

        button.edit-item-btn.active .font {
          color: $color-ineteractive-active;
        }
      }
    }
  }
}