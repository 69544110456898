@import '../../styles/variables.scss';

.filter-popup {
  position: fixed;
  top: 1.8%;
  left: 87%;
  transform: translateX(-55%);
  background-color: #F9F9F9;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  padding: 20px;
  max-width: 400px;
  width: 100%;
  height: 92vh;
  text-align: center;
  z-index: 1000;
  overflow-y: auto;
  .filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4%;
    .filter-header-text {
      display: flex;
      width: 92%;
      
      
      .filter-header-text-btn {
        border: none;
        background: none;
        padding: 0;
        width: 15%;
        .filter-header-text-icon{
          margin-top: 5%;
          width: 10%;
        } 
      }
      
      .filter-header-text-name {
        font-size: 0.8rem;
        width: 80%;
      }

    }
    .filter-header-btn {
      border: none;
      border-radius: 50%;
      width: 30px; 
      height: 30px;
      background-color: #F4F4F4;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      
      &:hover {
        background-color: #d3d3d3;
      }
    
      .filter-header-icon {
        width: 16px;
        height: 16px;
        fill: #006039; 
      }
    }
  }
  .div-select {
    display: flex;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
    .filter-button-container {
      display: flex;
      flex-wrap: wrap; 
    }
    .btns-select {
      margin-bottom: 0.2rem;
      display: flex;
      margin-left: 0.5rem;    
      font-family: $font-family;
      border-radius: 0.3rem;
      font-size: 0.8rem;
      color: #999999;
      border: 1px solid #F2F2F2;
      background-color: #F2F2F2;
      box-sizing: border-box;
      border: none;
      border-radius: 4px;
      padding: 8px;
      .delete-icon {
        margin-left: 0.5rem;
        cursor: pointer;
      }
    }
  }
  .btns-popup {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 8rem;
    .filter-popup-results {
      background-color: $color-primary;
      color: #fff;
      border: none;
      width: 100%;
      padding: 15px 30px;
      font-size: 0.8rem;
      border-radius: 5px;
      &:hover {
          background-color: $color-ineteractive-hover;
        }  
    }
    
    .filter-popup-delete {
      background-color: $color-red;
      color: #fff;
      border: none;
      width: 100%;
      padding: 15px 30px;
      font-size: 0.8rem;
      border-radius: 5px;
      margin-bottom: 0.5rem;
      &:hover {
          background-color: $color-red-light;
      }
    }
  }

.btn-filter {
    display: flex;
    width: 100%;
    padding: 22px;
    border-radius: 5px;
    margin-bottom: 0.9rem;

    background-color: #fff;
    border: none;

    &:hover {
        background-color: #d2d2d2;
    }
    .btn-titre {
        display: flex;
        width: 100%;
        font-size: 0.8rem;
    }
    .btn-icon {
        color: green;
    }

    .btn-selection-icon {
      margin-right: 3rem;
    }
}

.search-container {
  font-family: $font-family;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 2px;
  background-color: #F5F5F5;
}
.loop-icon {
  width: 16px;
  margin-right: 5px;
  color: black;
  padding-bottom: 2%;
}

.search-bar {
    width: 100%;
    padding: 14px;
    border: none;
    background-color: #F5F5F5;
    border-radius: 2px;
    box-sizing: border-box;
    margin-bottom: 10px;
    font-family: $font-family;
}

.radio-filter {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
    display: flex;
    align-items: center;
  }
  
  .radio-input {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    cursor: pointer;
    border-radius: 0;
  }
  
  .radio-filter-text {
    font-size: $font-family;
    margin-left: 2%;
    text-align: left;
    font-size: 0.8rem;
    width: 38%;
  }

  .radio-filter-number {
    font-size: $font-family;
    font-size: 0.97rem;
    margin-left: 35%;
    text-align: right;
    width: 5rem;
    color: #c6c6c6;
  }

  @media screen and (max-width: 1280px) {
    font-size: $font-family;
    width: 88%;
    transform: translateX(-88%);
    position: fixed;
    z-index: 1;
    margin-bottom: 100rem;
    .filter-popup-infos {
      margin-bottom: 8rem;
    }
    .btns-popup {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 4rem;
    }

    .radio-input {
      width: 1.8rem;
      height: 1.5rem;
      margin-right: 0.5rem;
      cursor: pointer;
      border-radius: 0;
    }

    .radio-filter-text {
      font-size: $font-family;
      margin-left: 2%;
      text-align: left;
      font-size: 0.75rem;
      width: 33%;
    }
  }
}
