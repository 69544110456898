div.page-title{
    div{
        p{
            margin: 2rem;
        }
    }
    p.description-fourth {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
    .description-title-2 {
        margin-top: 0.8rem;
    }
}


div.page-content{
    div.content-fields{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 1rem;
    }

    div.cancel-field{
        margin-top: auto;
        margin-bottom: 0.5rem;
    }

    div.next-field {
        margin-top: 1.2rem;
        margin-bottom: 1rem;
    }
}

@media (max-width: 600px) {
    div.page-title{
        .description-title-1 {
            font-size: 16px;
        }
        .description-title-2 {
            font-size: 16px;
            margin-top: -1.5rem;
            margin-bottom: -0.9rem;
        }
    }   
}

